.wrapper {
	@apply hidden lg:flex sticky z-100 top-0 right-0 left-0 -mt-[7.375rem] -mx-[4.375rem];
	@apply py-[2.1875rem] px-[4.375rem] bg-white dark:bg-gray-dark;
}

.firastarLogoTitle {
	@apply flex justify-start items-center basis-1/5 text-primary text-5 xl:text-6 font-bold gap-3;
}

.menu {
	@apply flex justify-center items-center flex-1 text-black-medium dark:text-white;
	@apply font-semibold whitespace-nowrap text-4.5 xl:text-5 gap-8 xl:gap-12;
}

.switchers {
	@apply basis-1/5 flex justify-end items-center gap-5;
}

.activeItem {
	@apply text-primary;
}
