.wrapper {
	@apply lg:px-8 lg:pt-16 lg:pb-32 pt-10 pb-12 scroll-m-12 lg:scroll-m-20;
}

.title {
	@apply text-4 lg:text-8 font-semibold mr-2;
}

.cols {
	@apply flex gap-16 xl:gap-24 items-center;
}

.rightCol {
	@apply flex-1;
}

.leftCol {
	@apply flex-1 hidden lg:flex justify-center items-center;
}

.cardList {
	@apply flex flex-col gap-2 lg:gap-4 xl:gap-8 my-4 lg:my-14;
}

.descText {
	@apply text-black-pure dark:text-white text-3 lg:text-4 font-semibold text-center;
}

.link {
	@apply text-primary;
}
