.wrapper {
	@apply lg:min-h-[calc(100vh_-_365px)];
}

.editor {
	@apply mt-8 flex flex-col gap-y-12 lg:flex-row ;
}

.beforeWrap,
.afterWrap {
	@apply border-2 border-gray-light-shade rounded-2xl p-6 pb-3 flex-1;
	@apply w-full min-h-[23.5rem] lg:min-h-[18rem] flex flex-col items-end gap-4;
}

.beforeWrap {
	@apply rtl:lg:border-l-0 rtl:lg:rounded-l-none ltr:lg:border-r-0 ltr:lg:rounded-r-none;
}

.afterWrap {
	@apply rtl:lg:rounded-r-none ltr:lg:rounded-l-none;
}

.before,
.after {
	@apply w-full min-h-[17.5rem] lg:min-h-[12rem] outline-none px-2 text-3.5 lg:text-4;
	@apply dark:bg-gray-dark resize-none;
}

.after {
	@apply lg:h-full cursor-default;
}

.copyBtn {
	@apply w-24 h-11 lg:w-28 lg:h-12 border text-white bg-secondary px-4 py-2;
	@apply border-secondary rounded-lg font-semibold hover:shadow-md ;
	@apply transition duration-300 text-3.5 lg:text-4 active:bg-white active:text-secondary;
}
