.wrapper {
	@apply flex justify-center lg:justify-between items-center px-5 py-5;
	@apply lg:py-9 border-t border-silver mt-20;
}

.contactInfo {
	@apply hidden lg:flex flex-col gap-2;
}

.phone,
.email {
	@apply flex items-center gap-2 font-normal text-4.5 text-black-ultra dark:text-white;
}

.statement {
	@apply hidden lg:flex gap-2 items-start rtl:items-center;
}

.socialMedia {
	@apply flex flex-row-reverse gap-6;
}
