.wrapper {
	@apply min-h-[400px] lg:min-h-[calc(50vh)] max-h-[62.5rem];
	@apply dark:bg-[url("/images/about-us-dark.jpg")] bg-[url("/images/about-us-light.jpg")];
	@apply -mx-[2.1875rem] lg:-mx-[4.375rem] px-[2.9375rem] lg:px-[6.5rem];
	@apply flex flex-col justify-center items-start;
}

.title {
	@apply text-5 lg:text-8 font-bold text-black-ultra dark:text-white mb-2 lg:mb-6 mr-2;
}

.text {
	@apply text-3.5 lg:text-4 font-normal text-sonic-silver dark:text-white lg:leading-10 whitespace-pre-line;
}
