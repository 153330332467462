.mainBtn {
	@apply w-36 h-11 lg:w-56 lg:h-16 border-2 border-primary rounded-[20px] px-4 py-3;
	@apply flex gap-3 justify-center items-center ;
	@apply text-primary text-3.5 lg:text-5 font-semibold;
	@apply hover:shadow-[inset_0_-4rem_0_0_#15C39A] hover:text-white transition duration-1000;

	-webkit-tap-highlight-color: transparent;
}

.secondaryBtn {
	@apply w-24 h-11 lg:w-28 lg:h-12 border text-white bg-primary px-4 py-2;
	@apply border-primary rounded-lg font-semibold hover:shadow-md ;
	@apply transition duration-300 text-3.5 lg:text-4 active:bg-white active:text-primary;

	-webkit-tap-highlight-color: transparent;
}
