.wrapper {
	@apply flex lg:hidden items-center sticky z-100 top-0 -mt-[6.875rem] -mx-[2.1875rem];
	@apply px-[2.1875rem] py-[1rem] right-0 left-0 bg-white dark:bg-gray-dark;
}

.logo {
	@apply flex justify-center items-center basis-1/3 min-w-[7.5rem] text-primary text-4 font-bold gap-3;
}

.menu {
	@apply flex items-center basis-1/3;
}

.switcher {
	@apply basis-1/3 flex justify-end items-center;
}
