.wrapper {
	@apply rounded-lg border border-gray-light-shade flex gap-4 items-center;
	@apply min-h-[3.5rem] px-4 py-2 lg:min-h-[6rem] lg:px-6 lg:py-2;
}

.checkCircle {
	@apply min-w-[1.5rem] h-6 lg:min-w-[2.5rem] lg:h-10 rounded-full bg-secondary flex justify-center items-center;
}

.check {
	@apply w-2 h-2 lg:w-4 lg:h-4;
}

.featureText {
	@apply text-black-ultra dark:text-white text-3.5 lg:text-4 lg:font-medium;
}
