@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "../public/fonts/dana.scss";

html,
body {
	font-family:
		dana,
		tahoma,
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	scroll-behavior: auto;
	min-width: 280px;
}

html.light {
	color: black;
	background-color: white;
}

html.dark {
	color: white;
	background: #242526;
}

body {
	margin: 7.375rem 4.375rem 0;
	padding: 0;
}

@media only screen and (max-width: 1024px) {
	body {
		margin: 6.875rem 2.1875rem 0;
		padding: 0;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
	transition: color, background-color 0.5s;
}

// override react toggle styles
.react-toggle-track {
	background-color: #757575 !important;
}

.react-toggle-track-check {
	width: 18px !important;
	height: 18px !important;
	left: 5px !important;
	top: 0 !important;
}

.react-toggle-track-x {
	width: 20px !important;
	height: 20px !important;
	right: 3px !important;
	top: 1px !important;
}

.react-toggle--checked .react-toggle-thumb {
	border-color: #757575 !important;
}

.react-toggle--focus .react-toggle-thumb {
	box-shadow: none !important;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	box-shadow: 0 0 3px 3px #15c39a !important;
}

.react-toggle:hover .react-toggle-thumb {
	box-shadow: 0 0 3px 3px #15c39a !important;
}

@media only screen and (max-width: 1024px) {
	.react-toggle-track {
		width: 42px !important;
		height: 20px !important;
	}

	.react-toggle--checked .react-toggle-thumb {
		left: 24px !important;
	}

	.react-toggle-track-check {
		left: 4px !important;
		top: 2px !important;
	}

	.react-toggle-track-x {
		right: 1px !important;
		top: 2px !important;
	}

	.react-toggle-thumb {
		width: 18px !important;
		height: 18px !important;
	}
}
